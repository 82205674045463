/**
 * 回答項目クラス
 */
(function () {
    this.ManageForm = (function () {
        function ManageForm() {
            this.setListener();
        }

        ManageForm.prototype.setListener = function () {

            var form_list = document.getElementById('form_list');
            var sortable = Sortable.create(form_list, {handle: ".sort_area"});
            var deletedFreeContentIds = [];
            /**
             * 自由項目表示制御(旧招待状)
             */
            // 初期表示
            if(!$('ul.old_design #check_free').prop("checked")){
                $('ul.old_design [name=free_form]').css('display','none');
            }

            // 初期表示 自由質問の追加ボタン
            if($('ul.new_design li.free_content').length == 3){
                $('ul.new_design li.free_add').css('display','none');
            }
            // チェックボックス選択時の切り替え
            $(document).on('click', 'ul.old_design input[name=check_free]', function () {
                $('ul.old_design [name=free_form]').slideToggle();
            });

            /**
             * 自由項目表示制御(新招待状)
             */
            // 自由項目追加ボタン
            $(document).on('click', 'ul.new_design div.free_add_button' , function() {

                // 既存のfree3, free4, free5の要素を確認
                var existingFreeIndices = [];
                for (var i = 3; i <= 5; i++) {
                    if ($('#free' + i).length > 0) {
                        existingFreeIndices.push(i);
                    }
                }
                // 存在しない最小のインデックスを見つける
                var new_free_index = 3;
                for (var i = 3; i <= 5; i++) {
                    if (!existingFreeIndices.includes(i)) {
                        new_free_index = i;
                        break;
                    }
                }

                // 新しいフォームのインデックスを設定
                var new_form_index = new_free_index - 2;
                var new_form_html = '\
                    <li class="free_content" id="free'+new_free_index+'">\
                        <div class="free_content_wrap">\
                            <div class="left">\
                                <span class="sort_area"><i class="fa-solid fa-grip-dots-vertical"></i></span>\
                                <label>自由項目</label>\
                            </div>\
                            <div class="data-select right">\
                                <span class="select">\
                                    <select name="form_type_'+new_form_index+'">\
                                        <option value="1">記述式</option>\
                                        <option value="2">ラジオボタン（単一選択）</option>\
                                        <option value="3">チェックボックス（複数選択可）</option>\
                                    </select>\
                                </span>\
                            </div>\
                        </div>\
                        <div class="left free_form">\
                            <input type="text" name="free'+new_free_index+'" value="" placeholder="質問内容をご入力下さい">\
                        </div>\
                        <div class="free_option_wrap" style="display: none;">\
                            <div class="free_option">\
                                <input type="text" id="v'+new_form_index+'_1" name="option_'+new_form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option"><i class="ion-android-close"></i></div>\
                                <p class="require_text"></p>\
                            </div>\
                            <div class="free_option">\
                                <input type="text" id="v'+new_form_index+'_2" name="option_'+new_form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option "><i class="ion-android-close"></i></div>\
                                <p class="require_text"></p>\
                            </div>\
                            <div class="free_option">\
                                <input type="text" id="v'+new_form_index+'_3" name="option_'+new_form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option"><i class=" ion-android-close"></i></div>\
                                <p class="require_text"></p>\
                            </div>\
                            <div class="free_option_add_trigger">\
                                <button type="button" class="">追加 <i class="ion-android-add"></i></button>\
                            </div>\
                        </div>\
                        <div class="free_selector right">\
                            <label class="inline_radio_label">\
                                <input type="radio" name="check_free_'+new_form_index+'" value="2" ><span>必須</span>\
                            </label>\
                            <label class="inline_radio_label">\
                                <input type="radio" name="check_free_'+new_form_index+'" value="1" checked><span>任意</span>\
                            </label>\
                            <button type="button" class="delete_free_form">削除 <i class="ion-android-close"></i></button>\
                        </div>\
                    </li>';
                $(this).parent().before(new_form_html);
                if(new_form_index === 3 || existingFreeIndices.length === 2){
                    $('ul.new_design li.free_add').css('display','none');
                }
            });

            // 自由項目削除ボタン
            $(document).on('click', 'ul.new_design button.delete_free_form', function() {
                var $this = $(this);
                var free_content_id = $this.parents('li.free_content').attr('id');
                // Ajaxで回答があるかを確認
                $.ajax({
                    url: '/manage_form/check_answer',
                    type: 'POST',
                    data: { id: free_content_id },
                    success: function(response) {
                        if (response.hasAnswer) {
                            // 回答がある場合、確認アラートを表示
                            if (confirm('既に回答データが存在します。この自由項目の回答データも削除されますが、よろしいですか？？')) {
                                // ユーザーが削除を確認した場合
                                // 即時削除ではなく、IDを保存
                                deletedFreeContentIds.push(free_content_id);
                                // 削除対象の質問IDをサーバーに送信するために、隠しフィールドにセット
                                $('<input>').attr({
                                    type: 'hidden',
                                    name: 'deleted_ids', // フォームデータの名前
                                    value: deletedFreeContentIds.join(',') // 配列をカンマ区切りの文字列に変換
                                }).appendTo('#update_form');

                                $this.parents('li.free_content').remove();
                                $('ul.new_design li.free_add').css('display', 'flex');
                            }
                        } else {
                            // 回答がない場合、直接削除
                            $this.parents('li.free_content').remove();
                            $('ul.new_design li.free_add').css('display', 'flex');
                        }
                    },
                    error: function() {
                        alert('回答の確認中にエラーが発生しました。');
                    }
                });
            });
            
            /**
             * 自由質問項目　選択肢追加(新招待状)
             */
            // 選択肢追加フォーム
            $(document).on('click', 'div.free_option_add_trigger' , function() {
                var free_option_wrap = $(this).parent().parent().attr('id');
                var form_index = free_option_wrap.slice(-1) - 2;
                var new_input_id = $(this).parent().find('div.free_option').length+1;
                var class_name = $(this).parent().find('div.free_option').find('span').first().attr('class');

                var new_input_html = '\
                    <div class="free_option">\
                        <span class="'+class_name+'"></span>\
                        <input type="text" id="v'+form_index+'_'+new_input_id+'" name="option_'+form_index+'[]" value="" placeholder="選択肢">\
                        <div class="delete_free_option"><i class="ion-android-close"></i></div>\
                    </div>';
                $(this).before(new_input_html);
            });

            // 選択肢削除ボタン
            $(document).on('click', 'div.free_option div.delete_free_option' , function() {
                var free_option_wrap = $(this).parent().parent();
                var input_id = $(this).parent().find('input').attr('id');
                var form_index = input_id.charAt(1);
                // 選択肢削除
                $(this).parent('.free_option').remove();
                $.each($(free_option_wrap).find('.free_option'), function(i, form){
                    $(form).find('input').attr('id', 'v'+form_index+'_'+(i+1));
                });
            });

            /**
             * 
             */
            // 質問項目の種類選択
            $(document).on('change', 'div.data-select select' , function() {
                var val = $(this).val();
                var form_index = parseInt($(this).attr('name').slice(-1));
                var target = $(this).closest(".free_content_wrap");
                target.nextAll().eq(1).find('span').remove();
                if (val == 1) {
                    target.nextAll().eq(1).hide();
                } else if (val == 2) {
                    target.nextAll().eq(1).show();
                    var options = target.nextAll().eq(1).find('input');
                    if(options.length > 0) {
                        options.each(function(index, element) {
                            $('<span class="radio_icon"></span>').insertBefore(element);
                        });
                    } else {
                        var new_input_html = '\
                        <div class="free_option">\
                            <span class="radio_icon"></span>\
                            <input type="text" id="v'+form_index+'_1" name="option_'+form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option"><i class="ion-android-close"></i></div>\
                            <p class="require_text"></p>\
                        </div>\
                        <div class="free_option">\
                            <span class="radio_icon"></span>\
                            <input type="text" id="v'+form_index+'_2" name="option_'+form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option "><i class="ion-android-close"></i></div>\
                            <p class="require_text"></p>\
                        </div>\
                        <div class="free_option">\
                            <span class="radio_icon"></span>\
                            <input type="text" id="v'+form_index+'_3" name="option_'+form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option"><i class=" ion-android-close"></i></div>\
                            <p class="require_text"></p>\
                        </div>';
                        target.nextAll().eq(1).find('.free_option_add_trigger').before(new_input_html);
                    }
                } else {
                    target.nextAll().eq(1).show();
                    var options = target.nextAll().eq(1).find('input');
                    if(options.length > 0) {
                        options.each(function(index, element) {
                            $('<span class="check_icon"></span>').insertBefore(element);
                        });
                    } else {
                        var new_input_html = '\
                        <div class="free_option">\
                            <span class="check_icon"></span>\
                            <input type="text" id="v'+form_index+'_1" name="option_'+form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option"><i class="ion-android-close"></i></div>\
                            <p class="require_text"></p>\
                        </div>\
                        <div class="free_option">\
                            <span class="check_icon"></span>\
                            <input type="text" id="v'+form_index+'_2" name="option_'+form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option "><i class="ion-android-close"></i></div>\
                            <p class="require_text"></p>\
                        </div>\
                        <div class="free_option">\
                            <span class="check_icon"></span>\
                            <input type="text" id="v'+form_index+'_3" name="option_'+form_index+'[]" value="" placeholder="選択肢"><div class="delete_free_option"><i class=" ion-android-close"></i></div>\
                            <p class="require_text"></p>\
                        </div>';
                        target.nextAll().eq(1).find('.free_option_add_trigger').before(new_input_html);
                    }
                }
            });

            /**
             * 備考表示制御
             */
            $(document).on('click', 'input[name=is_remarks_enable]', function () {
                $('[name=remarks]').slideToggle();
            });

            /**
             * メールアドレス非表示の場合、自動返信メールを入力させない
             */
            changeMailForm();
            $(document).on('click', 'input[name=mail]', function () {
                changeMailForm();
            });
            function changeMailForm(){
                var mail_selected = $("#update_form").find('input[name="mail"]:checked').val();
                if(mail_selected === '0'){
                    if(!$(".guest_mail").hasClass('hidden_mail')){
                        $(".guest_mail").addClass('hidden_mail');
                        $(".guest_mail textarea").prop( 'disabled', true );
                    }
                }else{
                    if($(".guest_mail").hasClass('hidden_mail')){
                        $(".guest_mail").removeClass('hidden_mail');
                        $(".guest_mail textarea").prop( 'disabled', false );
                    }
                }
            }

            /**
             * その他のお知らせ　表示制御
             */

            $(document).on('click', 'input[name=is_notice]', function () {
                var in_notice = $('input[name="is_notice"]:checked').val();
                if (in_notice == 0) {
                    $('div[class=notice_box]').toggle().hide();
                } else {
                    $('div[class=notice_box]').toggle().show();
                }
            });

            /**
             * プレビューボタンクリック時
             */
            $(document).on('click', '[data-action="preview"]', function () {
                var $form_preview = $('#form_preview');
                var $form_update = $('#update_form');
                var $form_order = [];
                $('input:checked').each(function(index, element) {
                    var $name = $(element).attr('name');
                    if ($name === 'check_free_1') {
                        $name = "free3";
                    } else if ($name === 'check_free_2') {
                        $name = "free4";
                    } else if ($name === 'check_free_3') {
                        $name = "free5";
                    }
                    $form_order.push($name);
                });

                var $option_1 = [];
                $('[name="option_1[]"]').each(function(index, element) {
                    if ($(element).val()) {
                        $option_1.push($(element).val());
                    }
                });

                var $option_2 = [];
                $('[name="option_2[]"]').each(function(index, element) {
                    if ($(element).val()) {
                        $option_2.push($(element).val());
                    }
                });

                var $option_3 = [];
                $('[name="option_3[]"]').each(function(index, element) {
                    if ($(element).val()) {
                        $option_3.push($(element).val());
                    }
                });
                
                $form_preview.find('[name="kana"]').val($form_update.find('[name="kana"]:checked').val());
                $form_preview.find('[name="mail"]').val($form_update.find('[name="mail"]:checked').val());
                $form_preview.find('[name="name_latin"]').val($form_update.find('[name="name_latin"]:checked').val());
                $form_preview.find('[name="guest_type"]').val($form_update.find('[name="guest_type"]:checked').val());
                $form_preview.find('[name="sex"]').val($form_update.find('[name="sex"]:checked').val());
                $form_preview.find('[name="post_code"]').val($form_update.find('[name="post_code"]:checked').val());
                $form_preview.find('[name="address"]').val($form_update.find('[name="address"]:checked').val());
                $form_preview.find('[name="tel"]').val($form_update.find('[name="tel"]:checked').val());
                $form_preview.find('[name="allergy"]').val($form_update.find('[name="allergy"]:checked').val());
                $form_preview.find('[name="message"]').val($form_update.find('[name="message"]:checked').val());

                $form_preview.find('[name="free3"]').val($form_update.find('[name="free3"]').val());
                $form_preview.find('[name="form_type_1"]').val($form_update.find('[name=form_type_1] option:selected').val());
                $form_preview.find('[name="check_free_1"]').val($form_update.find('[name="check_free_1"]:checked').val());
                $form_preview.find('[name="option_1"]').val($option_1);
                
                $form_preview.find('[name="free4"]').val($form_update.find('[name="free4"]').val());
                $form_preview.find('[name="form_type_2"]').val($form_update.find('[name="form_type_2"] option:selected').val());
                $form_preview.find('[name="check_free_2"]').val($form_update.find('[name="check_free_2"]:checked').val());
                $form_preview.find('[name="option_2"]').val($option_2);

                $form_preview.find('[name="free5"]').val($form_update.find('[name="free5"]').val());
                $form_preview.find('[name="form_type_3"]').val($form_update.find('[name="form_type_3"] option:selected').val());
                $form_preview.find('[name="check_free_3"]').val($form_update.find('[name="check_free_3"]:checked').val());
                $form_preview.find('[name="option_3"]').val($option_3);

                $form_preview.find('[name="companion"]').val($form_update.find('[name="companion"]:checked').val());
                $form_preview.find('[name="online"]').val($form_update.find('[name="online"]:checked').val());
                $form_preview.find('[name="is_notice"]').val($form_update.find('[name="is_notice"]:checked').val());
                $form_preview.find('[name="notice"]').val($form_update.find('[name="notice"]').val());
                $form_preview.find('[name="form_order"]').val($form_order);

                $form_preview.submit();
            });

        };

        return ManageForm;
    })();
}).call(this);